import styled from 'styled-components'
import { TABLET_BREAKPOINT } from 'styles/breakpoints'
import { transition1 } from 'styles/transformation'
import { handleFontSize } from 'styles/typography'

export const HeaderMobileNavDrawerBlanket = styled.div<{
  $isOpen?: boolean
}>`
  z-index: 100;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  background: ${({ $isOpen }) =>
    $isOpen ? 'rgba(0, 0, 0 , 0.5)' : 'rgba(0, 0, 0 , 0)'};
  transition: background 0.3s linear;

  @media screen and (min-width: ${TABLET_BREAKPOINT + 1}px) {
    display: none;
  }
`

export const HeaderMobileNavDrawerWrapper = styled.div<{
  $isOpen?: boolean
}>`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.border};
  background: ${({ theme }) => theme.background.primary};
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  transform: ${({ $isOpen }) =>
    $isOpen ? 'translateX(0px)' : 'translateX(-100%)'};
  ${transition1}
  display: flex;
  flex-direction: column;
`

export const HeaderMobileNavDrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.border};
  padding: 20px;

  svg {
    color: ${({ theme }) => theme.secondary};
    font-size: ${() => handleFontSize('5xl')};
    cursor: pointer;

    :hover {
      color: ${({ theme }) => theme.primary};
    }
  }
`

export const HeaderMobileNavDrawerList = styled.div`
  padding: 10px;
  padding-bottom: 100px;
  flex: 1;
  overflow: auto;
`

export const HeaderMobileNavItem = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  margin-bottom: 10px;

  :hover {
    background: ${({ theme }) => theme.background.tertiary};
    cursor: pointer;
    border-radius: 5px;
  }

  svg {
    color: ${({ theme }) => theme.secondary};
    font-size: ${() => handleFontSize('5xl')};
    margin-right: 10px;
  }
`
