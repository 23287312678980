import { gql } from '@apollo/client'

export const GET_ALL_SEARCH = gql`
  query ($limit: Int, $query: String) {
    currentUserData {
      id
      savedJobs(query: $query, limit: $limit) {
        # Saved Job Data
        id
        jobReferenceClean
        savedAt
        unsavedAt
        # Job Data
        expired
        jobReferenceClean
        location
        title
        city
        state
        # zipVal
        country
        postedAt
        companyName
        body
        bodyPreview
        employmentType
        startDate
        salaryMin
        salaryMax
        # fintrosJobUuid
        # fintrosLink
        remote
        # fintrosSalaryType
        postingDeadline
        # jobBenefits
        # formattedLocationVerbose
        formattedLocationAbbr
        formattedAddress
        # latitude
        # longitude
        # jobDepartments
        currency
        fintrosCompanyUuid
        # promoted
        # streetAddress
        source
        # enrichedSalaryMedianUsd
        # enrichedSalaryLowUsd
        # enrichedSalaryHighUsd
        enrichedSalaryMedianCad
        enrichedSalaryLowCad
        enrichedSalaryHighCad
        enrichedSalaryCount
        # formattedLocationPermutations
        # Company Data
        companyAnonymizedName
        companyLogoUrl
        companyBannerUrl
        # companyType
        fintrosCompanyUuid
        companyIndustry
        companyDomain
        # companyIsPublic
        # companyDescription
        companyFoundingYear
        companyEmployeeCount
        companyDepartments
      }
      uploads(query: $query, limit: $limit) {
        id
        name
        fileUrl
        fileType
        createdAt
        updatedAt
        createdAtTimeAgo
        updatedAtTimeAgo
        contentType
      }

      jobApplications(query: $query, limit: $limit) {
        totalCount
        hasNextPage
        id
        jobReferenceClean
        dateApplied
        archivedAt
        expired
        jobReferenceClean
        location
        title
        city
        state
        # zipVal
        country
        postedAt
        companyName
        body
        bodyPreview
        employmentType
        startDate
        salaryMin
        salaryMax
        # fintrosJobUuid
        # fintrosLink
        remote
        # fintrosSalaryType
        postingDeadline
        # jobBenefits
        # formattedLocationVerbose
        formattedLocationAbbr
        formattedAddress
        # latitude
        # longitude
        # jobDepartments
        currency
        fintrosCompanyUuid
        # promoted
        # streetAddress
        source
        # enrichedSalaryMedianUsd
        # enrichedSalaryLowUsd
        # enrichedSalaryHighUsd
        enrichedSalaryMedianCad
        enrichedSalaryLowCad
        enrichedSalaryHighCad
        enrichedSalaryCount
        # formattedLocationPermutations
        companyAnonymizedName
        companyLogoUrl
        companyBannerUrl
        # companyType
        companyIndustry
        companyDomain
        # companyIsPublic
        # companyDescription
        companyFoundingYear
        companyEmployeeCount
        companyDepartments
        jobApplicationQuestions {
          id
          question
          questionType
          answerChoices
          submittedAnswer
        }
      }
    }
  }
`

export const GET_SAVED_SEARCHES = gql`
  query ($savedSearchType: SavedSearch!) {
    currentUserData {
      id
      savedSearches(savedSearchType: $savedSearchType) {
        id
        name
        searchFilters
        savedSearchType
        createdAt
      }
    }
  }
`

export const SAVE_SEARCH = gql`
  mutation (
    $name: String!
    $searchFilters: JSON!
    $savedSearchType: SavedSearch!
  ) {
    savedSearchCreate(
      name: $name
      searchFilters: $searchFilters
      savedSearchType: $savedSearchType
    ) {
      savedSearch {
        id
        name
        searchFilters
        savedSearchType
      }
      errors
      status
      statusCode
    }
  }
`

export const DELETE_SEARCH = gql`
  mutation ($searchId: ID!, $savedSearchType: SavedSearch!) {
    savedSearchDelete(id: $searchId, savedSearchType: $savedSearchType) {
      savedSearch {
        id
        name
        searchFilters
      }
      errors
      status
      statusCode
    }
  }
`

export const GET_SAVED_JOB = gql`
  query ($jobId: ID) {
    currentUserData {
      id
      savedJobs(companyJobId: $jobId) {
        id
        companyJobId
        companyId
        saved
        savedAt
        unsavedAt
        title
        location
        department
        expirationDate
      }
    }
  }
`

export const REMOVE_SAVED_JOB = gql`
  mutation ($jobId: ID!) {
    savedJobUnsave(id: $jobId) {
      savedJob {
        id
        companyJobId
        companyId
        title
        location
        department
        expirationDate
        saved
        savedAt
        unsavedAt
      }
      errors
      status
      statusCode
    }
  }
`

export const GET_JOB_ALERTS = gql`
  query ($jobAlertType: JobAlert!) {
    currentUserData {
      id
      jobAlerts(jobAlertType: $jobAlertType) {
        id
        name
        frequency
        searchFilters
        jobAlertType
        createdAt
      }
    }
  }
`

export const GET_COMPANY_ALERTS = gql`
  query ($jobAlertType: JobAlert!) {
    currentUserData {
      id
      jobAlerts(jobAlertType: $jobAlertType) {
        id
        name
        frequency
        searchFilters
        jobAlertType
        createdAt
      }
    }
  }
`

export const CREATE_JOB_ALERT = gql`
  mutation (
    $name: ID!
    $frequency: Frequency!
    $searchFilters: JSON!
    $jobAlertType: JobAlert!
  ) {
    jobAlertCreate(
      name: $name
      searchFilters: $searchFilters
      frequency: $frequency
      jobAlertType: $jobAlertType
    ) {
      jobAlert {
        id
        name
        searchFilters
        frequency
        jobAlertType
      }
      errors
      status
      statusCode
    }
  }
`

export const CREATE_COMPANY_ALERT = gql`
  mutation (
    $name: ID!
    $frequency: Frequency!
    $jobAlertType: JobAlert!
    $searchFilters: JSON!
  ) {
    jobAlertCreate(
      name: $name
      searchFilters: $searchFilters
      frequency: $frequency
      jobAlertType: $jobAlertType
    ) {
      jobAlert {
        id
        name
        searchFilters
        frequency
      }
      errors
      status
      statusCode
    }
  }
`

export const EDIT_JOB_ALERT = gql`
  mutation (
    $id: ID!
    $name: String!
    $searchFilters: JSON!
    $frequency: Frequency!
    $jobAlertType: JobAlert!
  ) {
    jobAlertUpdateContent(
      id: $id
      name: $name
      searchFilters: $searchFilters
      frequency: $frequency
      jobAlertType: $jobAlertType
    ) {
      jobAlert {
        id
        name
        searchFilters
        frequency
      }
      errors
      status
      statusCode
    }
  }
`

export const DELETE_JOB_ALERT = gql`
  mutation ($id: ID!) {
    jobAlertDelete(id: $id) {
      jobAlert {
        id
        name
        searchFilters
        frequency
      }
      errors
      status
      statusCode
    }
  }
`

export const DELETE_COMPANY_ALERT = gql`
  mutation ($id: ID!) {
    jobAlertDelete(id: $id) {
      jobAlert {
        id
        name
        searchFilters
        frequency
      }
      errors
      status
      statusCode
    }
  }
`

export const GET_RECENT_SEARCHES = gql`
  query ($limit: Int, $hideAllSearches: Boolean) {
    currentUserData {
      id
      recentSearches(limit: $limit, hideAllSearches: $hideAllSearches) {
        id
        term
        searchType
        updatedAt
      }
    }
  }
`

export const CREATE_RECENT_SEARCH = gql`
  mutation ($term: String!, $searchType: RecentSearch!) {
    recentSearchCreate(term: $term, searchType: $searchType) {
      recentSearch {
        id
        term
        searchType
      }
      errors
      status
      statusCode
    }
  }
`

export const CLEAR_RECENT_SEARCH = gql`
  mutation ($id: ID!) {
    recentSearchClear(id: $id) {
      recentSearch {
        id
        term
        searchType
      }
      errors
      status
      statusCode
    }
  }
`

export const CLEAR_ALL_RECENT_SEARCHES = gql`
  mutation {
    recentSearchClearAll {
      remainingRecentSearchesIds
      errors
      status
      statusCode
    }
  }
`
