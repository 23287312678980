import styled from 'styled-components'
import { TABLET_BREAKPOINT } from 'styles/breakpoints'
import { InputContainer } from 'components/Input/styles'

export const SearchInputContainer = styled(InputContainer)<{
  size?: string
  attach?: null | 'top' | 'left' | 'bottom' | 'right' | 'all'
  isSearchBarFocused?: boolean
}>`
  width: 100%;
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.background.secondary};

  input {
    margin-left: ${({ isSearchBarFocused }) =>
      isSearchBarFocused ? '0' : '-35px'};
    padding-left: ${({ isSearchBarFocused }) =>
      isSearchBarFocused ? '10px' : '35px'};
  }
`

export const SearchInputIconWrapper = styled.div`
  margin: 2px 10px 0px;
  z-index: 10;
`

export const SearchBarWrapper = styled.div<{
  hideMobile: boolean
  maxWidth: boolean
}>`
  position: relative;
  flex: 1;
  background: ${({ theme }) => theme.background.primary};
  display: flex;
  align-items: center;

  .location,
  .remote {
    width: fit-content;
  }

  @media screen and (max-width: ${TABLET_BREAKPOINT}px) {
    position: relative;
    padding: 0px;
    display: ${({ hideMobile }) => (hideMobile ? 'none' : 'block')};
    flex: 1;
  }
`

export const ClearButtonWrapper = styled.div`
  color: ${({ theme }) => theme.secondary};
  position: absolute;
  right: 1px;
  border-radius: 5px;
  background: ${({ theme }) => theme.background.primary};
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;

  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.primary};
  }
`

export const KbdIconGroup = styled.div`
  position: absolute;
  right: 5px;
  display: flex;
`
