import styled from 'styled-components'

export const KeyButtonBase = styled.span`
  border: 0;
  border-radius: 3px;
  box-shadow: inset 0 -2px 0 0 ${({ theme }) => theme.background.tertiary},
    inset 0 0 1px 1px ${({ theme }) => theme.background.secondary},
    0 1px 2px 1px ${({ theme }) => theme.background.tertiary};
  color: ${({ theme }) => theme.primary};
  display: flex;
  height: 18px;
  justify-content: center;
  align-items: center;
  margin-right: 0.4em;
  padding: 0 0 2px;
  font-size: 65%;
  width: 20px;
`
