import React, {
  useLayoutEffect,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react'
import {
  ClearButtonWrapper,
  KbdIconGroup,
  SearchBarWrapper,
  SearchInputContainer,
  SearchInputIconWrapper,
} from './styles'
import { useRouter } from 'next/router'
import { Icon, ICON_VARIANTS } from 'components/Icon'
import { InputElement } from 'components/Input/styles'
import { useMutation, useQuery } from '@apollo/client'
import {
  CREATE_RECENT_SEARCH,
  GET_RECENT_SEARCHES,
} from 'graphql/queries/search'
import {
  SEARCH_TARGET_TO_RECENT_SEARCH_TYPE,
  SEARCH_TARGETS_ROUTES,
  SEARCH_TYPE_TO_SEARCH_TARGET,
} from 'components/SearchBar/constants'
import { SkeletonLine } from 'components/Skeleton'
import { useWindowSize } from 'contexts/WindowSizeContext'
import { MdClear } from 'react-icons/md'
import { isMacOs } from 'react-device-detect'
import { CtrlKeyIcon } from 'components/Icon/keys/CtrlKeyIcon'
import { KeyButtonBase } from 'components/Icon/keys/KeyButtonBase'
import { Wrapper } from 'styles/styleSystem/wrapper'
import useKeyPress, { KeyBehavior } from 'hooks/useKeyPress'
import _isEmpty from 'lodash-es/isEmpty'
import _values from 'lodash-es/values'
import dynamic from 'next/dynamic'

const SearchBarDropdown = dynamic(() =>
  import('./SearchBarDropdown').then((res) => res.SearchBarDropdown)
)

type valueof<T> = T[keyof T]

const SearchBar = ({
  hideMobile = true,
  loggedOut = false,
  placeholder = 'Search or jump to...',
  maxWidth = false,
  loading = false,
  getIsSuggestionDropdownOpened = null,
  getOnCloseSuggestionDropdown = null,
}) => {
  const router = useRouter()
  const searchInputRef = useRef<HTMLInputElement>(null)
  const [searchDropdownOpen, setSearchDropdownOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [inputFocused, setInputFocused] = useState(false)
  const [currentSearchType, setCurrentSearchType] = useState<
    valueof<typeof SEARCH_TARGETS_ROUTES>
  >(SEARCH_TARGETS_ROUTES.JOBS)
  const { isMobile } = useWindowSize()

  useKeyPress(
    'k',
    () => {
      if (inputFocused || searchDropdownOpen) {
        onCloseDropdown()
        return searchInputRef.current?.blur()
      }

      searchInputRef.current?.focus()
      onOpenDropdown()
    },
    KeyBehavior.down,
    true
  )

  useKeyPress(
    'Escape',
    () => {
      if (!inputFocused) return
      searchInputRef.current?.blur()
    },
    KeyBehavior.down,
    false
  )

  const {
    loading: recentSearchLoading,
    data,
    refetch: refetchRecentSearches,
  } = useQuery(GET_RECENT_SEARCHES, {
    variables: {
      limit: 5,
      hideAllSearches: isMobile,
    },
    skip: loggedOut || !searchDropdownOpen,
  })
  const [saveRecentSearch] = useMutation(CREATE_RECENT_SEARCH, {
    onCompleted: refetchRecentSearches,
  })

  const {
    pathname,
    query: { term },
  } = router

  const currentSubRoute = pathname.split('/')[1]

  const onCloseSuggestionDropdown = useCallback(
    () => setSearchDropdownOpen(false),
    [setSearchDropdownOpen]
  )

  useEffect(() => {
    getOnCloseSuggestionDropdown?.(onCloseSuggestionDropdown)
  }, [onCloseSuggestionDropdown])

  useLayoutEffect(() => {
    getIsSuggestionDropdownOpened?.(searchDropdownOpen)
  }, [searchDropdownOpen])

  useEffect(() => {
    if (_values(SEARCH_TARGETS_ROUTES).includes(pathname)) {
      return setCurrentSearchType(pathname)
    }
    setCurrentSearchType(SEARCH_TARGETS_ROUTES.JOBS)
  }, [pathname, setCurrentSearchType])

  useEffect(() => {
    setSearchTerm(decodeURIComponent((term as string) || ''))
  }, [term, currentSubRoute])

  useEffect(() => {
    const searchType = SEARCH_TYPE_TO_SEARCH_TARGET[currentSubRoute]
    searchType && setCurrentSearchType(searchType)
  }, [currentSubRoute, setCurrentSearchType])

  const handleSearch = useCallback(
    async (term = '', type = '') => {
      setSearchDropdownOpen(false)
      const termToBeSearched = !_isEmpty(term) ? term : searchTerm
      const typeToBeSearched = !_isEmpty(type) ? type : currentSearchType

      if (!loggedOut) {
        await saveRecentSearch({
          variables: {
            term: termToBeSearched,
            searchType: SEARCH_TARGET_TO_RECENT_SEARCH_TYPE[typeToBeSearched],
          },
        })
      }

      if (_isEmpty(termToBeSearched)) {
        return await router.push(currentSearchType)
      }

      if (typeToBeSearched !== router.pathname) {
        return await router.push(
          `${typeToBeSearched}/?term=${encodeURIComponent(termToBeSearched)}`
        )
      }

      // Shallow routes if search type = current page
      router.query.term = termToBeSearched
      await router.push(router, undefined, {
        shallow: true,
      })
    },
    [
      setSearchDropdownOpen,
      saveRecentSearch,
      currentSearchType,
      searchTerm,
      router,
    ]
  )

  const onSearchByCategory = useCallback(
    (term: string = searchTerm, type: string = currentSearchType) => {
      setSearchTerm(term)
      handleSearch(term, type)
    },
    [setSearchTerm, handleSearch]
  )

  const handleInputBlur = useCallback(
    () => searchInputRef.current?.blur?.(),
    [searchInputRef]
  )
  const handleInputFocus = useCallback(
    () => searchInputRef.current.focus?.(),
    [searchInputRef]
  )

  const onInputChange = (e) => {
    const value = e.target.value
    setSearchTerm(value)
    !searchDropdownOpen && setSearchDropdownOpen(true)
  }

  const onFocused = () => {
    searchInputRef.current.select()
    setInputFocused(true)
  }

  const onBlur = () => {
    searchInputRef.current.blur()
    setInputFocused(false)
  }

  const onOpenDropdown = useCallback(
    () => setSearchDropdownOpen(true),
    [setSearchDropdownOpen]
  )
  const onCloseDropdown = useCallback(
    () => setSearchDropdownOpen(false),
    [setSearchDropdownOpen]
  )

  if (loading) return <SkeletonLine height="30px" width="100%" margin="0" />

  const isSearchBarFocused = document.activeElement === searchInputRef.current

  return (
    <SearchBarWrapper hideMobile={hideMobile} maxWidth={maxWidth}>
      <form style={{ flex: 1 }} onSubmit={(e) => e.preventDefault()}>
        <SearchInputContainer
          transparent
          onClick={onOpenDropdown}
          isSearchBarFocused={isSearchBarFocused}
        >
          {!isSearchBarFocused && (
            <SearchInputIconWrapper>
              <Icon variant={ICON_VARIANTS.magnifyGlass} />
            </SearchInputIconWrapper>
          )}
          <InputElement
            ref={searchInputRef}
            value={searchTerm}
            onChange={onInputChange}
            onFocus={onFocused}
            onBlur={onBlur}
            placeholder={placeholder}
            transparent
            noTransition
          />
          {searchTerm.length > 0 && (
            <ClearButtonWrapper onClick={() => setSearchTerm('')}>
              <MdClear />
            </ClearButtonWrapper>
          )}
          {!isMobile && _isEmpty(searchTerm) && !inputFocused && (
            <KbdIconGroup>
              <KeyButtonBase>
                {isMacOs ? (
                  '⌘'
                ) : (
                  <Wrapper pt="1px">
                    <CtrlKeyIcon />
                  </Wrapper>
                )}
              </KeyButtonBase>
              <KeyButtonBase>K</KeyButtonBase>
            </KbdIconGroup>
          )}
        </SearchInputContainer>
      </form>
      {searchDropdownOpen && (
        <SearchBarDropdown
          loggedOut={loggedOut}
          searchTerm={searchTerm}
          onSearchByCategory={onSearchByCategory}
          currentSearchType={currentSearchType}
          setCurrentSearchType={setCurrentSearchType}
          isOpen={searchDropdownOpen}
          isSearchBarFocused={inputFocused}
          onClose={onCloseDropdown}
          handleInputBlur={handleInputBlur}
          handleInputFocus={handleInputFocus}
          saveRecentSearch={saveRecentSearch}
          loading={recentSearchLoading}
          data={data}
          refetchRecentSearches={refetchRecentSearches}
        />
      )}
    </SearchBarWrapper>
  )
}

export default React.memo(SearchBar)
