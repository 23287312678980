// Saved Types
export const MY_JOBS_LOADED = 'MY_JOBS_LOADED'
export const JOB_DETAILS_LOADED = 'JOB_DETAILS_LOADED'
export const COMPANY_DETAILS_LOADED = 'COMPANY_DETAILS_LOADED'
export const SEARCH_JOBS_LOADED = 'SEARCH_JOBS_LOADED'

// Modal types
export const MODAL_TYPES = {
  SHOW_JOB_APPLICATION: 'SHOW_JOB_APPLICATION',
  SHOW_RESUME_UPLOAD: 'SHOW_RESUME_UPLOAD',
  SHOW_ALL_UPLOAD: 'SHOW_ALL_UPLOAD',
  SHOW_PHONE_VERIFICATION: 'SHOW_PHONE_VERIFICATION',
  SHOW_POSITION_FORM: 'SHOW_POSITION_FORM',
  SHOW_EDUCATION_FORM: 'SHOW_EDUCATION_FORM',
  SHOW_LANGUAGE_FORM: 'SHOW_LANGUAGE_FORM',
  SHOW_CERTIFICATION_FORM: 'SHOW_CERTIFICATION_FORM',
  SHOW_SUBMIT_FEEDBACK: 'SHOW_SUBMIT_FEEDBACK',
  SHOW_THEME_SELECTION: 'SHOW_THEME_SELECTION',
  SHOW_JOB_SHARE: 'SHOW_JOB_SHARE',
  SHOW_RESUME_PDF_PREVIEW: 'SHOW_RESUME_PDF_PREVIEW',
} as const
// Settings Types
export const VISIBILITY_LOADED = 'VISIBILITY_LOADED'
export const JOB_SENIORITY_LOADED = 'JOB_SENIORITY_LOADED'
export const NOTIFICATIONS_UPDATED = 'NOTIFICATIONS_UPDATED'
export const MAILING_ADDRESSES_LOADED = 'MAILING_ADDRESSES_LOADED'
export const MAILING_ADDRESSES_UPDATED = 'MAILING_ADDRESSES_UPDATED'
export const SETTINGS_ADDED = 'SETTINGS_ADDED'
export const SETTINGS_REORDERED = 'SETTINGS_REORDERED'
export const SETTINGS_EDITED = 'SETTINGS_EDITED'
export const LANGUAGE_LOADED = 'LANGUAGE_LOADED'
export const LANGUAGE_UPDATED = 'UPDATE_LANGUAGE'
export const NOTIFICATIONS_LOADED = 'NOTIFICATIONS_LOADED'
export const SALARY_LOADED = 'SALARY_LOADED'
export const SALARY_UPDATED = 'SALARY_UPDATED'
export const LOCATION_PREFERENCES_LOADED = 'LOCATION_PREFERENCES_LOADED'
export const REMOTE_PREFERENCE_UPDATED = 'REMOTE_PREFERENCE_UPDATED'
export const WORKPLACE_LOADED = 'WORKPLACE_LOADED'
export const TARGET_INDUSTRIES_LOADED = 'TARGET_INDUSTRIES_LOADED'
export const BLACKLIST_INDUSTRIES_LOADED = 'BLACKLIST_INDUSTRIES_LOADED'
export const AVAILABILITY_LOADED = 'AVAILABILITY_LOADED'
export const AVAILABILITY_UPDATED = 'AVAILABILITY_UPDATED'
export const COMPANIES_LOADED = 'COMPANIES_LOADED'
export const COMPANIES_UPDATED = 'COMPANIES_UPDATED'

// Upload Types
export const MY_UPLOADS_LOADED = 'MY_UPLOADS_LOADED'

// Resume Types
export const RESUME_TEMPLATE_LOADED = 'RESUME_TEMPLATE_LOADED'
export const RESUME_LOADED = 'RESUME_LOADED'
export const TOGGLE_ANONYMOUS = 'TOGGLE_ANONYMOUS'
export const SET_ANONYMOUS = 'SET_ANONYMOUS'
export const PERSONAL_LOADED = 'PERSONAL_LOADED'
export const POSITIONS_LOADED = 'POSITIONS_LOADED'
export const POSITIONS_CREATED = 'POSITIONS_CREATED'
export const EDUCATION_LOADED = 'EDUCATION_LOADED'
export const EDUCATIONS_REORDERED = 'EDUCATIONS_REORDERED'
export const INITIAL_RESUME_LOAD = 'INITIAL_RESUME_LOAD'
export const PERSONAL_ADDED = 'PERSONAL_ADDED'
export const EDUCATION_ADDED = 'EDUCATION_ADDED'
export const EDUCATION_DELETED = 'EDUCATION_DELETED'
export const POSITION_ADDED = 'POSITION_ADDED'
export const POSITION_DELETED = 'POSITION_DELETED'
export const POSITIONS_REORDERED = 'POSITIONS_REORDERED'
export const LINKS_ADDED = 'LINKS_ADDED'
export const LANGUAGES_ADDED = 'LANGUAGES_ADDED'
export const LANGUAGES_DELETED = 'LANGUAGES_DELETED'
export const LANGUAGES_REORDERED = 'LANGUAGES_REORDERED'
export const CERTIFICATIONS_ADDED = 'CERTIFICATIONS_ADDED'
export const CERTIFICATIONS_DELETED = 'CERTIFICATIONS_DELETED'
export const CERTIFICATIONS_REORDERED = 'CERTIFICATIONS_REORDERED'
export const PHONE_NUMBER_LOADED = 'PHONE_NUMBER_LOADED'
export const SUMMARY_LOADED = 'SUMMARY_LOADED'
export const UPDATE_LAST_UPDATED = 'UPDATE_LAST_UPDATED'
