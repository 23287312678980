import { createContext, useContext, useReducer } from 'react'
import { MODAL_TYPES } from 'contexts/types'

const INITIAL_MODAL_STATE = {
  [MODAL_TYPES.SHOW_JOB_APPLICATION]: false,
  [MODAL_TYPES.SHOW_RESUME_UPLOAD]: false,
  [MODAL_TYPES.SHOW_ALL_UPLOAD]: false,
  [MODAL_TYPES.SHOW_PHONE_VERIFICATION]: false,
  [MODAL_TYPES.SHOW_POSITION_FORM]: false,
  [MODAL_TYPES.SHOW_EDUCATION_FORM]: false,
  [MODAL_TYPES.SHOW_LANGUAGE_FORM]: false,
  [MODAL_TYPES.SHOW_CERTIFICATION_FORM]: false,
  [MODAL_TYPES.SHOW_SUBMIT_FEEDBACK]: false,
  [MODAL_TYPES.SHOW_THEME_SELECTION]: false,
  [MODAL_TYPES.SHOW_JOB_SHARE]: false,
  [MODAL_TYPES.SHOW_RESUME_PDF_PREVIEW]: false,
  data: {},
  callback: null,
}

const ModalStateContext = createContext(null)
const ModalDispatchContext = createContext<
  React.Dispatch<{
    type: keyof typeof MODAL_TYPES
    payload: {
      open: boolean
      [x: string]: any
    }
  }>
>(null)

const reducer = (state, action) => {
  switch (action.type) {
    case MODAL_TYPES.SHOW_JOB_APPLICATION:
      return {
        ...state,
        [MODAL_TYPES.SHOW_JOB_APPLICATION]: action.payload.open,
        ...(action.payload.id && {
          id: action.payload.id,
        }),
        draftedJobIdsCache: action.payload.draftedJobIdsCache,
        removeDraftFromCache: action.payload.removeDraftFromCache,
        addDraftedJobIdToCache: action.payload.addDraftedJobIdToCache,
      }
    case MODAL_TYPES.SHOW_RESUME_UPLOAD:
      return {
        ...state,
        [MODAL_TYPES.SHOW_RESUME_UPLOAD]: action.payload.open,
        preSelectedFile: action.payload.preSelectedFile,
      }
    case MODAL_TYPES.SHOW_ALL_UPLOAD:
      return {
        ...state,
        [MODAL_TYPES.SHOW_ALL_UPLOAD]: action.payload.open,
        onSuccess: action.payload.onSuccess,
        fileType: action.payload.fileType,
        onboarding: action.payload.onboarding,
        preSelectedFile: action.payload.preSelectedFile,
      }
    case MODAL_TYPES.SHOW_PHONE_VERIFICATION:
      return {
        ...state,
        [MODAL_TYPES.SHOW_PHONE_VERIFICATION]: action.payload.open,
        ...(action.payload.data && { data: action.payload.data }),
      }
    case MODAL_TYPES.SHOW_SUBMIT_FEEDBACK:
      return {
        ...state,
        [MODAL_TYPES.SHOW_SUBMIT_FEEDBACK]: action.payload.open,
      }
    case MODAL_TYPES.SHOW_POSITION_FORM:
      return {
        ...state,
        [MODAL_TYPES.SHOW_POSITION_FORM]: action.payload.open,
      }
    case MODAL_TYPES.SHOW_EDUCATION_FORM:
      return {
        ...state,
        [MODAL_TYPES.SHOW_EDUCATION_FORM]: action.payload.open,
      }
    case MODAL_TYPES.SHOW_LANGUAGE_FORM:
      return {
        ...state,
        [MODAL_TYPES.SHOW_LANGUAGE_FORM]: action.payload.open,
      }
    case MODAL_TYPES.SHOW_CERTIFICATION_FORM:
      return {
        ...state,
        [MODAL_TYPES.SHOW_CERTIFICATION_FORM]: action.payload.open,
      }
    case MODAL_TYPES.SHOW_THEME_SELECTION:
      return {
        ...state,
        [MODAL_TYPES.SHOW_THEME_SELECTION]: action.payload.open,
      }
    case MODAL_TYPES.SHOW_JOB_SHARE:
      return {
        ...state,
        [MODAL_TYPES.SHOW_JOB_SHARE]: action.payload.open,
        job: action.payload.job,
        draftedJobIdsCache: action.payload.draftedJobIdsCache,
        removeDraftFromCache: action.payload.removeDraftFromCache,
        addDraftedJobIdToCache: action.payload.addDraftedJobIdToCache,
      }
    case MODAL_TYPES.SHOW_RESUME_PDF_PREVIEW:
      return {
        ...state,
        [MODAL_TYPES.SHOW_RESUME_PDF_PREVIEW]: action.payload.open,
        resume: action.payload.resume,
      }
    default:
      return state
  }
}

export const ModalContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_MODAL_STATE)
  return (
    <ModalDispatchContext.Provider value={dispatch}>
      <ModalStateContext.Provider value={state}>
        {children}
      </ModalStateContext.Provider>
    </ModalDispatchContext.Provider>
  )
}

export const useModalContext = () => useContext(ModalStateContext)
export const useModalDispatch = () => useContext(ModalDispatchContext)
