import { useWindowSize } from 'contexts/WindowSizeContext'
import { sleep } from 'helpers/sleep'
import { useRouter } from 'next/router'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useScrollDirection } from 'react-use-scroll-direction'

const DEFAULT_CLASS_NAME = 'styles__SearchPageWrapper'

interface MobileSearchBarContextProps {
  hideMobileBar: boolean
  showMobileSearchAffix: boolean
  cancelOverrideHideMobileBar: () => void
  setOverrideHideMobileBarValue: (val: boolean) => void
  setHideThreshold: React.Dispatch<React.SetStateAction<number>>
  resetStates: () => void
  setTargetClassName: React.Dispatch<React.SetStateAction<string>>
}

export const MobileSearchBarContext =
  createContext<MobileSearchBarContextProps>(null)

export const MobileSearchBarContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { pathname } = useRouter()
  const [hideThreshold, setHideThreshold] = useState(100)
  const [showMobileSearchAffix, setShowMobileSearchAffix] = useState(false)
  const [hideMobileBar, setHideMobileBar] = useState(false)
  const [overrideHideMobileBarValue, setOverrideHideMobileBarValue] =
    useState(null)
  const [targetClassName, setTargetClassName] = useState(DEFAULT_CLASS_NAME)
  const { isMobile } = useWindowSize()

  const { isScrollingUp, isScrollingDown, scrollTargetRef } =
    useScrollDirection()

  useEffect(() => {
    if (!isMobile) return
    const timeoutId = setTimeout(async () => {
      let targetElement = document.querySelector<HTMLElement>(
        `[class*='${targetClassName}']`
      )
      while (!targetElement) {
        await sleep(1000)
        targetElement = document.querySelector<HTMLElement>(
          `[class*='${targetClassName}']`
        )
      }
      scrollTargetRef(targetElement)
    }, 1000)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [targetClassName, isMobile, pathname])

  const handleAffixScroll = useCallback(
    (scrollTop: number) => {
      if (scrollTop > hideThreshold || hideMobileBar) {
        return !showMobileSearchAffix && setShowMobileSearchAffix(true)
      }
      return showMobileSearchAffix && setShowMobileSearchAffix(false)
    },
    [hideMobileBar, showMobileSearchAffix, hideThreshold]
  )

  const handleSearchBarScrollUp = useCallback(() => {
    if (hideMobileBar) {
      setHideMobileBar(false)
    }
  }, [hideMobileBar])

  const handleSearchBarScrollDown = useCallback(
    (scrollTop: number) => {
      if (scrollTop > hideThreshold) {
        return !hideMobileBar && setHideMobileBar(true)
      }
    },
    [hideMobileBar, hideThreshold]
  )

  useEffect(() => {
    if (!isMobile) return
    const targetElement = document.querySelector<HTMLElement>(
      `[class*='${targetClassName}']`
    )
    const scrollTop = targetElement?.scrollTop

    handleAffixScroll(scrollTop)

    if (isScrollingUp) {
      handleSearchBarScrollUp()
    }
    if (isScrollingDown) {
      handleSearchBarScrollDown(scrollTop)
    }
  })

  const resetStates = () => {
    setHideMobileBar(false)
    setOverrideHideMobileBarValue(null)
    setShowMobileSearchAffix(false)
    setHideThreshold(100)
    setTargetClassName(DEFAULT_CLASS_NAME)
  }

  useEffect(() => {
    resetStates()
  }, [pathname, isMobile])

  const setOverrideValue = (isHiding: boolean) =>
    setOverrideHideMobileBarValue(isHiding)

  const cancelOverrideHideMobileBar = () => setOverrideHideMobileBarValue(null)

  const isHidingSearchBar =
    overrideHideMobileBarValue !== null
      ? overrideHideMobileBarValue
      : hideMobileBar

  return (
    <MobileSearchBarContext.Provider
      value={{
        setTargetClassName,
        resetStates,
        setHideThreshold,
        hideMobileBar: isHidingSearchBar,
        showMobileSearchAffix,
        setOverrideHideMobileBarValue: setOverrideValue,
        cancelOverrideHideMobileBar,
      }}
    >
      {children}
    </MobileSearchBarContext.Provider>
  )
}

export const useAutoHideMobileSearchBar = () =>
  useContext(MobileSearchBarContext)
