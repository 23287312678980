import { useEffect } from 'react'

export enum KeyBehavior {
  up = 'keyup',
  down = 'keydown',
}

/**
 * useKeyPress
 * @param {string} key - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key press
 * @param {boolean} withCtrl - whether ctrl key is needed to run action function
 * @param {KeyBehavior} keyBehavior
 */
export default function useKeyPress(
  key,
  action,
  keyBehavior: KeyBehavior = KeyBehavior.up,
  withCtrl = false
) {
  useEffect(() => {
    function callback(e: KeyboardEvent) {
      const isCtrlPressed = e.metaKey || e.ctrlKey
      if (e.key === key) {
        if (withCtrl && !isCtrlPressed) return
        e.stopPropagation()
        e.preventDefault()
        action()
      }
    }
    window.addEventListener(keyBehavior, callback, { passive: true })
    return () => window.removeEventListener(keyBehavior, callback)
  })
}
