import styled from 'styled-components'

const DividerWrapper = styled.div<{
  margin?: number
  vertical?: boolean
  white?: boolean
  // only for vertical
  height?: number
}>`
  width: ${({ vertical }) => (vertical ? '1px' : '100%')};
  height: ${({ vertical, height }) =>
    vertical ? (height ? height + 'px' : '30px') : '1px'};
  min-height: ${({ vertical, height }) =>
    vertical ? (height ? height + 'px' : '30px') : '1px'};
  margin-top: ${({ margin, vertical }) => (vertical ? 0 : margin)}px;
  margin-bottom: ${({ margin, vertical }) => (vertical ? 0 : margin)}px;
  margin-left: ${({ margin, vertical }) => (vertical ? margin : 0)}px;
  margin-right: ${({ margin, vertical }) => (vertical ? margin : 0)}px;
  background: ${({ theme, white }) => (white ? theme.white : theme.border)};
`

export const Divider: React.FC<React.ComponentProps<typeof DividerWrapper>> = ({
  height = null,
  margin,
  vertical = false,
  white = false,
}) => {
  return (
    <DividerWrapper
      height={height}
      margin={margin}
      vertical={vertical}
      white={white}
    />
  )
}
