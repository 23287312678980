export const SEARCH_TARGETS_ROUTES = {
  ALL: '/search/all',
  JOBS: '/search/jobs',
  COMPANIES: '/search/companies',
  SALARIES: '/search/salaries',
  SAVED: '/saved',
  ASSESSMENTS: '/assessments',
  APPLICATIONS: '/applications',
  UPLOADS: '/uploads',
}

export const SEARCH_TARGETS_MAP = {
  [SEARCH_TARGETS_ROUTES.ALL]: 'all',
  [SEARCH_TARGETS_ROUTES.JOBS]: 'job_titles',
  [SEARCH_TARGETS_ROUTES.COMPANIES]: 'companies',
  [SEARCH_TARGETS_ROUTES.SALARIES]: 'salary_titles',
  [SEARCH_TARGETS_ROUTES.SAVED]: 'saved_jobs',
  [SEARCH_TARGETS_ROUTES.ASSESSMENTS]: 'assessments',
  [SEARCH_TARGETS_ROUTES.APPLICATIONS]: 'applied_jobs',
  [SEARCH_TARGETS_ROUTES.UPLOADS]: 'uploads',
}

export const SEARCH_TARGETS_RESULTS = {
  [SEARCH_TARGETS_ROUTES.JOBS]: 'job_results',
  [SEARCH_TARGETS_ROUTES.COMPANIES]: 'company_results',
  [SEARCH_TARGETS_ROUTES.SALARIES]: 'salaries_results',
  [SEARCH_TARGETS_ROUTES.SAVED]: 'saved_job_results',
  [SEARCH_TARGETS_ROUTES.ASSESSMENTS]: 'assessment_results',
  [SEARCH_TARGETS_ROUTES.APPLICATIONS]: 'applied_job_results',
  [SEARCH_TARGETS_ROUTES.UPLOADS]: 'upload_results',
}

export const SEARCH_TARGETS_RESULTS_TO_TARGET_ROUTE = {
  job_titles: SEARCH_TARGETS_ROUTES.JOBS,
  company_results: SEARCH_TARGETS_ROUTES.COMPANIES,
  salary_title_results: SEARCH_TARGETS_ROUTES.SALARIES,
  saved_results: SEARCH_TARGETS_ROUTES.SAVED,
  assessment_results: SEARCH_TARGETS_ROUTES.ASSESSMENTS,
  application_results: SEARCH_TARGETS_ROUTES.APPLICATIONS,
  upload_results: SEARCH_TARGETS_ROUTES.UPLOADS,
}

export const SEARCH_TARGETS_RESULTS_TO_ALL_TAB = {
  job_titles: 'jobs',
  company_results: 'companies',
  salary_title_results: 'salaries',
  saved_results: 'saved',
  assessment_results: 'assessments',
  application_results: 'applications',
  upload_results: 'uploads',
}

//NOTE: For generating GQL variables
export const SEARCH_TARGET_TO_RECENT_SEARCH_TYPE = {
  [SEARCH_TARGETS_ROUTES.ALL]: 'all_search',
  [SEARCH_TARGETS_ROUTES.JOBS]: 'job_search',
  [SEARCH_TARGETS_ROUTES.COMPANIES]: 'company_search',
  [SEARCH_TARGETS_ROUTES.SALARIES]: 'salary',
  [SEARCH_TARGETS_ROUTES.SAVED]: 'my_jobs',
  [SEARCH_TARGETS_ROUTES.ASSESSMENTS]: 'assessment',
  [SEARCH_TARGETS_ROUTES.APPLICATIONS]: 'applications',
  [SEARCH_TARGETS_ROUTES.UPLOADS]: 'uploads',
}
//NOTE: For generating GQL variables
export const ALL_TAB_TO_RECENT_SEARCH_TYPE = {
  [SEARCH_TARGETS_ROUTES.ALL]: 'all_search',
  [SEARCH_TARGETS_RESULTS_TO_ALL_TAB.job_titles]: 'job_search',
  [SEARCH_TARGETS_RESULTS_TO_ALL_TAB.company_results]: 'company_search',
  [SEARCH_TARGETS_RESULTS_TO_ALL_TAB.salary_title_results]: 'salary',
  [SEARCH_TARGETS_RESULTS_TO_ALL_TAB.saved_results]: 'saved_jobs',
  [SEARCH_TARGETS_RESULTS_TO_ALL_TAB.assessment_results]: 'assessment',
  [SEARCH_TARGETS_RESULTS_TO_ALL_TAB.application_results]: 'application',
  [SEARCH_TARGETS_RESULTS_TO_ALL_TAB.upload_results]: 'uploads',
}

export const SEARCH_TYPE_TO_SEARCH_TARGET = {
  jobs: SEARCH_TARGETS_ROUTES.JOBS,
  assessments: SEARCH_TARGETS_ROUTES.ASSESSMENTS,
  saved: SEARCH_TARGETS_ROUTES.SAVED,
  companies: SEARCH_TARGETS_ROUTES.COMPANIES,
  uploads: SEARCH_TARGETS_ROUTES.UPLOADS,
  salaries: SEARCH_TARGETS_ROUTES.SALARIES,
}

export const RECENT_SEARCH_TYPE_TO_SEARCH_TARGET = {
  job_search: SEARCH_TARGETS_ROUTES.JOBS,
  company_search: SEARCH_TARGETS_ROUTES.COMPANIES,
  salary: SEARCH_TARGETS_ROUTES.SALARIES,
  saved: SEARCH_TARGETS_ROUTES.SAVED,
  assessment: SEARCH_TARGETS_ROUTES.ASSESSMENTS,
  applications: SEARCH_TARGETS_ROUTES.APPLICATIONS,
  uploads: SEARCH_TARGETS_ROUTES.UPLOADS,
  all_search: SEARCH_TARGETS_ROUTES.ALL,
}
